import axios from "axios";
const axiosConfig = { headers: { 'Content-Type': 'application/json' } };

export default async function(data) {
    try {
        await axios.post('/api/sa', data, axiosConfig);
        return true;
    } catch (e) {
        console.error('sa', {e});
    }
    return false;
}