import React, { useState, useEffect } from 'react';
import Layout from '../components/App/Layout';
import SEO from '../components/App/SEO';
import { graphql, Link } from 'gatsby';
import TwitterVerifyLogo from '../assets/images/twitter/twitterVerifyLogo.svg'
import TwitterSuccess from '../assets/images/twitter/successVerify.svg'
import axios from 'axios';
import sa from "../utils/sa";

function getParameterByName(name, url = typeof window !== 'undefined' ? window.location.href : '') {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function TwitterVerify() {

    const [otp, setOtp] = useState(new Array(6).fill())
    const [errorMessage, setErrorMessage] = useState("");

    const tLink = getParameterByName('token');
    const codeLink = getParameterByName('code');
    const postRes = getParameterByName('form');

    useEffect(() => {
        if (codeLink) {
            setOtp(Object.assign([], codeLink));
        }
    }, []);

    const handleChange = (element, index) => {

        if(isNaN(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index) ? element.value : d )])

        console.log(element.value)
        // focus next input
        if(element.nextSibling) {
            element.nextSibling.focus();
        }
    }

    const showMessage = () => {
        setErrorMessage("PIN code incomplete.")
    }

    function submit() {
        
        var postData = {
            token: tLink,
            pin_code: otp.toString().replace(/,/g, "")
          };
          
          let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            mode: 'no-cors',            
          };
          
          axios.post('/api/twitter-verify-code', postData, axiosConfig)
          .then((res) => {
            if (res.status == 200) {
                try {
                  sa({event: 'twitter_page_activate_clicked'});
                  window.location.href = '/twitter-verify/?form=true'
                } catch(err) {
                  console.log(err)
                }
            }  
          })
          .catch((err) => {
            console.log(err);
            try {
              sa({event: 'twitter_page_activate_failed'});
              window.location.href = `/twitter-verify/?token=${postData.token}&code=${postData.pin_code}&form=false`
            } catch(err) {
              console.log(err)
            }
          })    
    }

    const disableSubmit = otp.includes(undefined) || otp.includes('') || otp.length < 6;

    console.log(postRes)

    return (
        <Layout>
        <SEO 
            postTitle='Twitter Verify | BlueMail App' 
            postDescription='Verify your twitter account'
            postImage=''
            postURL='twitter-verify'
            postSEO
        />
        
        {(postRes == null || postRes === 'false') && <div className="container" style={{
                maxWidth: "324px", height: "fit-content", border: "1px solid #808080", borderRadius: "10px",
                position: "absolute", top: "0", bottom: "0", right: "0", left: "0", margin: "auto"
            }}>            
            <div className='row'>
                <div className='col'>
                    <div className='row'>
                        <div className='col' style={{background: "#DCF0FF", display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                            <img src= {TwitterVerifyLogo} alt= "twitter verify logo" />
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col mt-4 pl-4 pr-4'>
                            <p style={{fontSize: "14px", fontWeight: "400", lineHeight: "19px", color: "#000"}}>
                                Enter the PIN code we have sent to your email user@example.com
                            </p>
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='m-auto'>
                            {
                                otp.map((data, index) => {
                                    return (
                                        <input style={{fontSize: "20px", fontWeight: "bold", width: "39px", height: "39px", background: "#F4FAFE",
                                        border: "1px solid #808080", borderRadius: "5px", textAlign: "center"}}
                                            className='col-2 ml-1 mr-1 mt-4 p-0'
                                            type= "text"
                                            key={index}
                                            maxLength= "1"
                                            value={data}
                                            onChange= {e=> handleChange(e.target, index)}
                                            onFocus= {e => e.target.select()}
                                        />
                                    ) 
                                })
                            }                            
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col pl-4 pr-4'>
                            <hr />
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col mt-5 pl-4 pr-4'>
                            {!disableSubmit && <button className="default-btn" style={{width: "100%"}} onClick={() => submit()}>
                                Activate
                            </button>}
                            {disableSubmit && <button className="btn btn-primary disabled" style={{width: "100%"}} onClick={() => showMessage()}>
                                Activate
                            </button>}
                            {disableSubmit && errorMessage && <div className='mt-3' style={{textAlign: "center", color: "red", fontSize: "12px"}}> {errorMessage} </div>}
                            {postRes === 'false' && <div className='mt-3' style={{textAlign: "center", color: "red", fontSize: "12px"}}> Incorrect PIN <br /> Please try again. </div>}
                        </div>   
                    </div>
                    <div className='row'>
                        <div className='col mt-3 mb-4 pl-4 pr-4'>
                            <p style={{fontSize: "11px", lineHeight: "15px", fontWeight: "600", color: "#808080"}}>
                                By continuing you agree to BlueMail's <br /> <Link style={{color: "#1F6BF1"}} to='/tos/'>Terms of Service</Link> and <Link style={{color: "#1F6BF1"}} to='/privacy/'>Privacy Policy</Link>
                            </p>
                        </div>                        
                    </div>
                </div>
            </div>               
        </div>}
        {postRes !== null && postRes === 'true' && <div className="container" style={{
                maxWidth: "324px", height: "fit-content", border: "1px solid #808080", borderRadius: "10px",
                position: "absolute", top: "0", bottom: "0", right: "0", left: "0", margin: "auto"
            }}>            
            <div className='row'>
                <div className='col'>
                    <div className='row'>
                        <div className='col' style={{background: "#DCF0FF", display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                            <img src= {TwitterVerifyLogo} alt= "twitter verify logo" />
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col mt-5 pl-4 pr-4'>
                            <p style={{fontSize: "28px", textAlign: "center", fontWeight: "700", lineHeight: "19px", color: "#44E279"}}>
                                Success!
                            </p>
                        </div>                        
                    </div>
                    <div className='row'>                        
                        <div className='col mt-3' style={{display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
                            <img src= {TwitterSuccess} alt= "twitter verify logo" />
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col mt-5 pl-4 pr-4'>
                            <p style={{fontSize: "15px", textAlign: "center", fontWeight: "400", lineHeight: "20px", color: "#000"}}>
                                Your Twitter account is now connected to your email address on BlueMail.
                            </p>
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='col mt-4 mb-4 pl-4 pr-4'>
                            <Link to='/twitter/commands/'>
                                <button className="default-btn" style={{width: "100%"}}>
                                    Twitter Assist
                                </button>
                            </Link>
                        </div>                        
                    </div>                    
                </div>
            </div>               
        </div>}
        </Layout>
    )
}

export default TwitterVerify;

export const query = graphql`
query TwitterVerifyPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
